var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "card-apply-job", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        { staticClass: "pb-1" },
        [
          _c(
            "b-media",
            { attrs: { "no-body": "" } },
            [
              _c(
                "b-media-aside",
                { staticClass: "mr-1" },
                [
                  _c("b-avatar", {
                    attrs: {
                      size: "42",
                      src: require("@/assets/images/portrait/small/avatar-s-20.jpg")
                    }
                  })
                ],
                1
              ),
              _c("b-media-body", [
                _c("h5", { staticClass: "mb-0" }, [_vm._v(" Mittie Evans ")]),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v("Updated 12m ago")
                ])
              ])
            ],
            1
          ),
          _c("b-badge", { attrs: { pill: "", variant: "light-primary" } }, [
            _vm._v(" Design ")
          ])
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c("h5", { staticClass: "apply-job-title" }, [
            _vm._v(
              " Need a designer to form branding essentials for my business. "
            )
          ]),
          _c("b-card-text", { staticClass: "mb-2" }, [
            _vm._v(
              " Design high-quality designs, graphics, mock-ups and layouts for both new and existing web sites/ web applications / mobile applications. "
            )
          ]),
          _c(
            "div",
            { staticClass: "apply-job-package bg-light-primary rounded" },
            [
              _c("div", [
                _c("sup", { staticClass: "text-body" }, [
                  _c("small", [_vm._v("$")])
                ]),
                _c("h2", { staticClass: "d-inline mr-25" }, [
                  _vm._v(" 9,800 ")
                ]),
                _c("sub", { staticClass: "text-body" }, [
                  _c("small", [_vm._v("/ month")])
                ])
              ]),
              _c("b-badge", { attrs: { variant: "light-primary", pill: "" } }, [
                _vm._v(" Full Time ")
              ])
            ],
            1
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { block: "", variant: "primary" }
            },
            [_vm._v(" Apply For This Job ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }