var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chats" },
    _vm._l(_vm.formattedChatData.formattedChatLog, function(msgGrp, index) {
      return _c(
        "div",
        {
          key: msgGrp.senderId + String(index),
          staticClass: "chat",
          class: {
            "chat-left": msgGrp.senderId === _vm.formattedChatData.contact.id
          }
        },
        [
          _c(
            "div",
            { staticClass: "chat-avatar" },
            [
              _c("b-avatar", {
                staticClass: "avatar-border-2 box-shadow-1",
                attrs: {
                  size: "36",
                  variant: "transparent",
                  src:
                    msgGrp.senderId === _vm.formattedChatData.contact.id
                      ? _vm.formattedChatData.contact.avatar
                      : _vm.profileUserAvatar
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "chat-body" },
            _vm._l(msgGrp.messages, function(msgData) {
              return _c(
                "div",
                { key: msgData.time, staticClass: "chat-content" },
                [_c("p", [_vm._v(_vm._s(msgData.msg))])]
              )
            }),
            0
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }