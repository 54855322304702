var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c(
        "b-col",
        { attrs: { lg: "7", md: "6" } },
        [_c("card-advance-congratulation")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "5", md: "6" } },
        [_c("card-advance-medal")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "4", md: "6" } },
        [_c("card-advance-employee-task")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "4", md: "6" } },
        [_c("card-advance-meetup")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "4", md: "6" } },
        [_c("card-advance-profile")],
        1
      ),
      _c("b-col", { attrs: { lg: "4", md: "6" } }, [_c("card-advance-job")], 1),
      _c(
        "b-col",
        { attrs: { lg: "4", md: "6" } },
        [_c("card-advance-transactions")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "4", md: "6" } },
        [_c("card-advance-pay-amount")],
        1
      ),
      _c("b-col", { attrs: { lg: "8" } }, [_c("card-advance-timeline")], 1),
      _c(
        "b-col",
        { attrs: { lg: "4", md: "6" } },
        [_c("card-advance-chat")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "4", md: "6" } },
        [_c("card-advance-bussiness-sharks")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "4", md: "6" } },
        [_c("card-advance-browser-states")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "4", md: "6" } },
        [_c("card-advance-app-design")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }