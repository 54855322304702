var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "card-payment", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        [
          _c("b-card-title", [_vm._v("Pay Amount")]),
          _c("b-card-title", { staticClass: "text-primary" }, [
            _vm._v(" $455.60 ")
          ])
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c(
            "b-form",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            label: "Card Number",
                            "label-for": "payment-card-number"
                          }
                        },
                        [
                          _c("cleave", {
                            staticClass: "form-control",
                            attrs: {
                              id: "payment-card-number",
                              raw: false,
                              options: _vm.options.creditCard,
                              placeholder: "2133 3244 4567 8921"
                            },
                            model: {
                              value: _vm.cardNumber,
                              callback: function($$v) {
                                _vm.cardNumber = $$v
                              },
                              expression: "cardNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: { label: "Expiry", "label-for": "expiry" }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "expiry",
                              type: "number",
                              placeholder: "MM/YY"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: { label: "CVV / CVC", "label-for": "cvv-cvc" }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "cvv-cvc ",
                              type: "number",
                              placeholder: "123"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: { label: "Input Name", "label-for": "name" }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "name", placeholder: "Curtis Stone" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(255, 255, 255, 0.15)",
                              expression: "'rgba(255, 255, 255, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          attrs: { block: "", variant: "primary" }
                        },
                        [_vm._v(" Make Payment ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }