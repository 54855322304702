var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "business-card", attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        { staticClass: "pb-1" },
        [
          _c("b-card-title", [_vm._v("For Business Sharks")]),
          _c("feather-icon", {
            staticClass: "cursor-pointer",
            attrs: { icon: "MoreVerticalIcon", size: "18" }
          })
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c("b-card-text", [
            _vm._v(
              "Here, i focus ona range of items and featured that we use in life without giving them"
            )
          ]),
          _c("h6", { staticClass: "mb-75" }, [_vm._v(" Basic price is $130 ")]),
          _c(
            "div",
            { staticClass: "business-items" },
            _vm._l(_vm.businessItems, function(businessItem) {
              return _c(
                "div",
                { key: businessItem.option, staticClass: "business-item" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between"
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        { attrs: { checked: businessItem.checked } },
                        [_vm._v(" " + _vm._s(businessItem.option) + " ")]
                      ),
                      _c(
                        "b-badge",
                        { attrs: { variant: businessItem.badgeColor } },
                        [_vm._v(" " + _vm._s(businessItem.money) + " ")]
                      )
                    ],
                    1
                  )
                ]
              )
            }),
            0
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "primary", block: "" }
            },
            [_vm._v(" Purchase ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }